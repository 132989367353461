import Container from 'typedi';

import { Template } from '@site-mate/dashpivot-shared-library';
import {
  IDropdownOption,
  IConfigMappingItem,
  ISingleConfigMapping,
  IStaticValueData,
  MappingConfigService,
  MappingValueData,
} from '@site-mate/sitemate-flowsite-shared';

import { Combobox } from '@/components';
import { useOptionsResolver } from '@/pages/flows/hooks/useOptionsResolver';
import { MappingLabels } from '@/pages/flows/types/labels';
import { nodeService } from '@/services';

import { useFilterByContainsQuery } from '../../hooks/useFilteredItems';

export interface MappingDropdownOptions {
  source?: IDropdownOption[];
  destination?: IDropdownOption[];
}

export interface FilterResult {
  id: string;
  label: string;
  valueData: MappingValueData;
}

type MappingItemProps<T> = {
  mappingConfig: T;
  configKey: string;
  template?: Template;
  onMappingChange: (mapping: T) => void;
  sourceLabel: string;
  destinationLabel: string;
  dropdownOptions?: MappingDropdownOptions;
  onMappingDelete?: (mapping: T) => void;
  index?: number;
};

function hasNoSelectableOptions(ref: unknown) {
  return Array.isArray(ref) && ref.length === 0;
}

// TODO don't think this matches the designs - can do as part of dropdown work
function generatePlaceholderText(
  field: string,
  obj: { ref: unknown; label: string }
) {
  if (hasNoSelectableOptions(obj.ref)) {
    return `Select another ${obj.label}`;
  }
  return obj.ref ? `Please select a ${field}` : `Select a ${obj.label} first`;
}

export const MappingItem = <
  T extends ISingleConfigMapping | IConfigMappingItem,
>({
  mappingConfig,
  configKey,
  template,
  onMappingChange,
  sourceLabel,
  destinationLabel,
  dropdownOptions,
  onMappingDelete,
  index,
}: MappingItemProps<T>) => {
  const mappingService = Container.get(MappingConfigService);
  const { source, destination } = mappingConfig || {};
  const isRequired =
    'isRequired' in mappingConfig ? mappingConfig.isRequired : true;
  const key = configKey.toLowerCase();
  const indexPostfix = index === undefined ? '' : `-${index}`;

  // TODO - source and destination should operate in the same way
  // dropdownOptions.source check was put in for fs-93
  // either generalise it or modify destination to work the same way
  const { data: sourceOptions } = useOptionsResolver(
    dropdownOptions?.source || template,
    source?.fields
  );
  const { data: destinationOptions } = useOptionsResolver(
    dropdownOptions?.destination,
    destination?.fields
  );

  const sourceValueData = source.valueData ?? source.defaultValueData;
  const selectedSource = sourceValueData?.fieldType
    ? mappingService
        .getFieldDataMapperFactory(sourceValueData.fieldType)
        ?.getOption(sourceValueData)
    : '';

  const destinationValueData = destination.valueData;
  const selectedDestination = destinationValueData?.fieldType
    ? mappingService
        .getFieldDataMapperFactory(destinationValueData?.fieldType)
        ?.getOption(destinationValueData)
    : '';

  const sourceDropdownOptions =
    sourceOptions?.map((sourceOption) => ({
      _id: sourceOption.id,
      label: sourceOption.label,
      value: sourceOption.id,
    })) ?? [];

  const { setQuery: setSourceQuery, items: filteredSourceOptions } =
    useFilterByContainsQuery(sourceDropdownOptions, 'label');

  const destinationDropdownOptions =
    dropdownOptions?.destination?.map((sourceOption) => ({
      _id: sourceOption.id,
      label: sourceOption.label,
      value: sourceOption.id,
    })) ?? [];

  const { setQuery: setDestinationQuery, items: filteredDestinationOptions } =
    useFilterByContainsQuery(destinationDropdownOptions, 'label');

  const handleSourceChange = (selected: {
    _id: string;
    label: string;
    value: string;
  }) => {
    const valueData = sourceOptions?.find(
      (option) => option.id === selected._id
    )?.valueData;

    onMappingChange(nodeService.setSourceValueData(mappingConfig, valueData));
  };

  const handleDestinationChange = (selected: {
    _id: string;
    label: string;
    value: string;
  }) => {
    const valueData = destinationOptions?.find(
      (option) => option.id === selected._id
    )?.valueData;

    onMappingChange(
      nodeService.setDestinationValueData(mappingConfig, valueData)
    );
  };

  return (
    <tr>
      <td className="border-slate-500 space-x-2 border p-2 text-sm">
        <span>{sourceLabel}</span>
        <Combobox
          dataTestId={`${key}-source${indexPostfix}-dropdown`}
          labelKey="label"
          valueKey="value"
          name={`${key}-source${indexPostfix}`}
          value={sourceDropdownOptions.find(
            (item) => item._id === selectedSource
          )}
          width="w-57.5"
          options={filteredSourceOptions}
          placeholder={
            !(isRequired && source.defaultValueData)
              ? generatePlaceholderText(
                  MappingLabels.get(configKey) ?? 'source',
                  {
                    label: 'template',
                    ref: template,
                  }
                )
              : undefined
          }
          hidePlaceholderOption={isRequired}
          disabled={!template || hasNoSelectableOptions(destinationOptions)}
          onInputChange={setSourceQuery}
          onChange={handleSourceChange}
        />
        {!isRequired && <span>(optional)</span>}
      </td>
      <td
        className={`border-slate-500 border px-2 text-sm ${
          destination?.isEditable ? 'space-x-2' : 'space-x-1'
        }`}
      >
        <span>{destinationLabel}</span>
        <span>
          {!destination?.isEditable ? (
            (destination?.valueData as IStaticValueData)?.label
          ) : (
            <>
              <Combobox
                labelKey="label"
                valueKey="value"
                width="w-57.5"
                dataTestId={`${key}-destination${indexPostfix}-dropdown`}
                name={`${key}-destination${indexPostfix}`}
                value={destinationDropdownOptions.find(
                  (item) => item._id === selectedDestination
                )}
                options={filteredDestinationOptions}
                placeholder={
                  !(isRequired && source.defaultValueData)
                    ? generatePlaceholderText(
                        MappingLabels.get(configKey) ?? 'destination',
                        {
                          label: 'template',
                          ref: template,
                        }
                      )
                    : undefined
                }
                hidePlaceholderOption={isRequired}
                disabled={
                  !dropdownOptions?.destination ||
                  hasNoSelectableOptions(dropdownOptions?.destination)
                }
                onInputChange={setDestinationQuery}
                onChange={handleDestinationChange}
              />
              {!isRequired && <span className="ml-2">(optional)</span>}
            </>
          )}
        </span>
        {onMappingDelete && (
          <button
            type="button"
            className="my-2 underline"
            data-testid={`delete-${key}${indexPostfix}-mapping`}
            onClick={() => onMappingDelete(mappingConfig)}
          >
            Remove
          </button>
        )}
      </td>
    </tr>
  );
};
