import Container from 'typedi';

import { Template } from '@site-mate/dashpivot-shared-library';
import {
  IFieldMappingConfigKey,
  IConfigMappingItem,
  INodeConfig,
  MappingConfigService,
} from '@site-mate/sitemate-flowsite-shared';

import {
  MappingDropdownOptions,
  MappingItem,
} from '@/pages/flows/components/flows/MappingItem';
import { nodeService } from '@/services';
import { Button } from '@/components';
import { IButtonColor } from '@/components/elements/button/Button.variants';

// TODO this has overlap with MappingLabels in flows/types/components and they should be consolidated
interface VariableMappingLabels {
  header: string;
  source: string;
  destination: string;
}

type VariableMappingsProps = {
  node: INodeConfig;
  template?: Template;
  mappingDefinition: IFieldMappingConfigKey;
  onNodeChange: (config: INodeConfig) => void;
  mappingLabels: VariableMappingLabels;
  dropdownOptions?: MappingDropdownOptions;
};

export const VariableMappings = ({
  node,
  template,
  mappingDefinition,
  onNodeChange,
  mappingLabels,
  dropdownOptions,
}: VariableMappingsProps) => {
  const mappingService = Container.get(MappingConfigService);
  const config = mappingService.getMultiMappingConfigForKey(
    mappingDefinition,
    node
  );
  if (!config) {
    return null;
  }

  const itemCount = config.items.length || 0;

  const handleMappingChange = (item: IConfigMappingItem) => {
    onNodeChange(nodeService.updateMappingItem(node, config, item));
  };

  const handleAddNewMapping = () => {
    onNodeChange(nodeService.addMappingItem(node, config));
  };

  const handleMappingDelete = (mapping: IConfigMappingItem) => {
    onNodeChange(nodeService.deleteMappingItem(node, config, mapping));
  };

  return (
    <>
      <p className="mb-2 text-sm text-default-text">{mappingLabels.header}</p>
      <table className="col-span-2 mb-2 w-full border-collapse border border-grey-2 text-left">
        <thead className="bg-grey-2 text-sm">
          <tr>
            <th className="border-slate-600 w-1/2 border p-2">Source</th>
            <th className="border-slate-600 w-1/2 border p-2">Destination</th>
          </tr>
        </thead>
        <tbody>
          {config.items.map((item, index) => (
            <MappingItem
              key={item._id}
              mappingConfig={item}
              configKey={mappingDefinition}
              template={template}
              sourceLabel={mappingLabels.source}
              destinationLabel={mappingLabels.destination}
              onMappingChange={handleMappingChange}
              onMappingDelete={itemCount > 1 ? handleMappingDelete : undefined}
              dropdownOptions={dropdownOptions}
              index={index}
            />
          ))}
        </tbody>
      </table>
      <Button color={IButtonColor.BLUE} onClick={handleAddNewMapping}>
        Add
      </Button>
    </>
  );
};
