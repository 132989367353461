import {
  CsvDataExportTypes,
  IHostedFileNodeConfigSettings,
} from '@site-mate/sitemate-flowsite-shared';

export const generateHostedFileConfig = () => ({
  mappings: [],
  settings: {
    dataExportType: CsvDataExportTypes.AutofillData,
  } as IHostedFileNodeConfigSettings,
});
