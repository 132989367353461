import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import Container from 'typedi';

import {
  IConnection,
  IQuickbooksInvoiceNodeConfigSettings,
  IInvoiceUpdateRule,
  IConfigMappingSet,
  IMultipleSetConfigMapping,
  INodeConfig,
  IQuickbooksInvoiceFieldMappingConfigKey,
  IXeroInvoiceFieldMappingConfigKey,
  IntegrationType,
  MappingConfigService,
  MappingConfigType,
} from '@site-mate/sitemate-flowsite-shared';

import { ConnectionSelector } from '@/pages/flows/components/connection-selector/ConnectionSelector';
import {
  FixedMappings,
  MultipleSetMappings,
  OutgoingActionProps,
} from '@/pages/flows/components/flows';
import { InvoiceActionNodeSettings } from '@/pages/flows/components/nodes/shared/InvoiceActionNodeSettings';
import { getSourceKey, useTemplate } from '@/pages/flows/hooks';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';
import { nodeService } from '@/services';

export const QuickbooksSupportedUpdateRules = [IInvoiceUpdateRule.CreateNew];

export const QuickbooksInvoiceActionNode = ({
  flow,
  onFlowChange,
  node,
}: OutgoingActionProps) => {
  const { workspaceId } = useWorkspaceContext();
  const templateId = nodeService.getTemplateId(flow);
  const templateQuery = useTemplate(workspaceId, templateId);
  const template = templateQuery.data;

  const mappingService = Container.get(MappingConfigService);
  const connectionId = node?.connectionId;

  const nodeConfigSettings =
    nodeService.getNodeSettings<IQuickbooksInvoiceNodeConfigSettings>(
      node.config
    );

  const handleSetConnection = useCallback(
    (connection: IConnection) => {
      onFlowChange(
        nodeService.setNode(
          flow,
          nodeService.setConnectionId(
            node,
            connection._id,
            getSourceKey(connection)
          )
        )
      );
    },
    [flow, node, onFlowChange]
  );

  const handleInvoiceSettingChange = useCallback(
    (settings: IQuickbooksInvoiceNodeConfigSettings) => {
      const newNode = cloneDeep(node);
      newNode.config.settings = settings;
      onFlowChange(nodeService.setNodeConfig(flow, newNode.config));
    },
    [flow, node, onFlowChange]
  );

  const handleNodeChange = (newNode: INodeConfig) => {
    onFlowChange(nodeService.setNodeConfig(flow, newNode));
  };

  const dashpivotToQuickbooksMappingLabels = {
    source: 'Dashpivot:',
    destination: 'QuickBooks:',
  };

  const lineItemConfigs =
    mappingService.getMappingConfigsForKeyAndType<IMultipleSetConfigMapping>(
      IXeroInvoiceFieldMappingConfigKey.LineItems,
      MappingConfigType.MultipleSet,
      node?.config
    );

  return (
    <div className="pb-2">
      <div className="mb-2 mt-4 font-bold">Action: Create invoice</div>
      <ConnectionSelector
        connectionType={IntegrationType.QUICKBOOKS}
        connectionId={connectionId}
        title="QuickBooks Company"
        onChangeConnection={handleSetConnection}
      />
      <p className="my-2 py-1 text-sm font-bold">Population</p>
      <p className="mb-4 text-sm">
        To create/edit invoice, you will need to define the sources of each
        input:
      </p>
      <FixedMappings
        node={node?.config}
        template={template}
        wrappedMappingConfigs={[
          {
            mappingKey: IQuickbooksInvoiceFieldMappingConfigKey.Customer,
            mappingLabel: dashpivotToQuickbooksMappingLabels,
          },
          {
            mappingKey: IQuickbooksInvoiceFieldMappingConfigKey.InvoiceDate,
            mappingLabel: dashpivotToQuickbooksMappingLabels,
          },
        ]}
        onNodeChange={handleNodeChange}
        header="General"
      />

      {lineItemConfigs
        ? lineItemConfigs.map((lineItemConfig) => {
            const handleMappingSetChange = (mappingSet: IConfigMappingSet) => {
              handleNodeChange(
                nodeService.updateMultiSetMappingConfig(
                  node.config,
                  lineItemConfig,
                  mappingSet
                )
              );
            };

            const handleMappingDelete = (mapping: IConfigMappingSet) => {
              handleNodeChange(
                nodeService.deleteMultiSetMappingConfig(
                  node.config,
                  lineItemConfig,
                  mapping
                )
              );
            };

            return (
              <MultipleSetMappings
                key={lineItemConfig._id}
                nodeConfig={node.config}
                lineItemConfig={lineItemConfig}
                onMappingSetChange={handleMappingSetChange}
                onMappingSetDelete={handleMappingDelete}
                onMappingSetCreated={handleNodeChange}
                template={template}
                wrappedMappingConfigs={[
                  {
                    mappingKey:
                      IQuickbooksInvoiceFieldMappingConfigKey.ItemName,
                    mappingLabel: dashpivotToQuickbooksMappingLabels,
                  },
                  {
                    mappingKey:
                      IQuickbooksInvoiceFieldMappingConfigKey.Quantity,
                    mappingLabel: dashpivotToQuickbooksMappingLabels,
                  },
                  {
                    mappingKey: IQuickbooksInvoiceFieldMappingConfigKey.Rate,
                    mappingLabel: dashpivotToQuickbooksMappingLabels,
                  },
                  {
                    mappingKey:
                      IQuickbooksInvoiceFieldMappingConfigKey.Description,
                    mappingLabel: dashpivotToQuickbooksMappingLabels,
                  },
                ]}
                header="Line source"
                buttonLabel="Add line source"
              />
            );
          })
        : undefined}

      <div className="mb-2 mt-2">
        <InvoiceActionNodeSettings
          heading="Editing existing invoices"
          description="If an invoice already exists in the same period for the same customer, how should new values be handled?"
          handlingOptions={QuickbooksSupportedUpdateRules}
          nodeConfigSettings={nodeConfigSettings}
          onNodeSettingsChange={handleInvoiceSettingChange}
        />
      </div>
    </div>
  );
};
