import {
  ConfigMapping,
  INodeType,
  IntegrationType,
  IXeroTimesheetNodeConfigSettings,
} from '@site-mate/sitemate-flowsite-shared';

import { IntegrationsMap } from '@/hooks';
import { ICreateProps } from '@/pages/flows/types';

import { mappingConfigs } from '../mappings/mappings';

export const generateXeroConfig = (
  createProps: ICreateProps,
  integrationsMap: IntegrationsMap
) => {
  const { nodeType } = createProps;

  const dashpivotId = integrationsMap.get(IntegrationType.DASHPIVOT)?._id;
  const xeroId = integrationsMap.get(IntegrationType.XERO)?._id;

  const config = {
    mappings: mappingConfigs[nodeType](dashpivotId, xeroId) as ConfigMapping[],
    settings: {} as IXeroTimesheetNodeConfigSettings,
  };

  if (nodeType === INodeType.XERO_TIMESHEET_V1) {
    config.settings.timesheetExists = undefined;
  }

  return config;
};
