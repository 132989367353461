import { cloneDeep } from 'lodash';
import { ulid } from 'ulid';

import {
  CsvDataExportTypes,
  IDropdownMappingField,
  IDropdownValueData,
  IEdge,
  IFlow,
  IFlowStatus,
  IHostedFileNodeConfigSettings,
  IInputDataValueData,
  IConfigMappingItem,
  IConfigMappingSet,
  IMappingDefinition,
  IMultipleSetConfigMapping,
  IMultipleSingleConfigMapping,
  INode,
  INodeCategory,
  INodeConfig,
  INodeStatus,
  INodeType,
  IntegrationType,
  ISingleConfigMapping,
  IXeroTimesheetNodeConfigSettings,
  ITimesheetUpdateRule,
  LatestSchemaVersions,
  ConfigMapping,
  MappingField,
  MappingFieldTypes,
} from '@site-mate/sitemate-flowsite-shared';
import { Flake } from '@site-mate/sitemate-global-shared';

import { emptyIntegrationsMap, IntegrationsMap } from '@/hooks/useIntegrations';
import { ICreateProps } from '@/pages/flows/types';
import { configMap } from '@/services/config';
import { metadataMap } from '@/services/metadata';

const NODE_INDEX = 0;
export const NEW_FLOW_ID = 'New';

export enum IFlowEvent {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

const getIntegrationId = (
  nodeType: INodeType,
  integrationsMap: IntegrationsMap
) => {
  switch (nodeType) {
    case INodeType.XERO_INVOICE_V1:
    case INodeType.XERO_TIMESHEET_V1:
      return integrationsMap.get(IntegrationType.XERO)?._id;
    case INodeType.POWERBI_CONNECTOR_V1:
    case INodeType.EXCEL_CONNECTOR_V1:
    default:
      return '';
  }
};

const generateConfig = (
  createProps: ICreateProps,
  integrationsMap: IntegrationsMap
): INodeConfig => configMap[createProps.nodeType](createProps, integrationsMap);

const generateNode = async (
  createProps: ICreateProps,
  integrationsMap: IntegrationsMap
): Promise<Partial<INode>> => {
  const { nodeType } = createProps;
  const nodeId = await Flake.generate(Flake.prefixes.Node);
  return {
    _id: nodeId,
    integrationId: getIntegrationId(nodeType, integrationsMap),
    type: nodeType,
    description:
      'node which triggers, filters and executes a run for the given flow',
    status: INodeStatus.PENDING,
    category: INodeCategory.ACTION,
    connectionId: '',
    input: {},
    output: {},
    logs: [],
    errors: [],
    title: nodeType,
    config: generateConfig(createProps, integrationsMap),
  };
};

export const generateMetadata = (createProps: ICreateProps, flow: IFlow) =>
  metadataMap[createProps.nodeType](flow);

// TODO: flow template generation could be extracted elsewhere to simplify this file
export const generateNewFlow = async (
  createProps: ICreateProps,
  integrationsMap: IntegrationsMap = emptyIntegrationsMap
): Promise<IFlow> => {
  const newFlow = {
    _id: NEW_FLOW_ID,
    status: IFlowStatus.DRAFT,
    version: 1,
    nodes: [(await generateNode(createProps, integrationsMap)) as INode],
    edges: [] as IEdge[],
    schemaVersion: LatestSchemaVersions.flow,
  } as unknown as IFlow;
  newFlow.metadata = generateMetadata(createProps, newFlow);
  return newFlow;
};

export function getTemplateId(flow: IFlow, orgTemplate?: boolean) {
  if (orgTemplate !== undefined) {
    return orgTemplate
      ? flow.metadata.triggerFilter.orgTemplateId
      : flow.metadata.triggerFilter.templateId;
  }
  return (
    flow.metadata.triggerFilter.orgTemplateId ||
    flow.metadata.triggerFilter.templateId
  );
}

export function setTemplateId(
  flow: IFlow,
  templateId: string,
  orgTemplate = false
) {
  const currentId = getTemplateId(flow, orgTemplate);
  if (currentId === templateId) {
    return flow;
  }
  const newFlow = cloneDeep(flow);
  if (orgTemplate) {
    newFlow.metadata.triggerFilter.orgTemplateId = templateId;
    delete newFlow.metadata.triggerFilter.templateId;
  } else {
    newFlow.metadata.triggerFilter.templateId = templateId;
    delete newFlow.metadata.triggerFilter.orgTemplateId;
  }
  return newFlow;
}

export function getTriggerSchedule(flow: IFlow) {
  return flow?.metadata?.triggerSchedule?.rule || '';
}

export function setTriggerSchedule(flow: IFlow, rule: string) {
  const currentSchedule = getTriggerSchedule(flow);

  if (currentSchedule === rule) {
    return flow;
  }

  const newFlow = cloneDeep(flow);
  newFlow.metadata.triggerSchedule.rule = rule;
  return newFlow;
}

export function setPathRegex(flow: IFlow, path: string) {
  const newPath = path ? `^${path.replace(/\//g, '\\/')}\\/` : path;
  if (flow.metadata.triggerFilter.pathRegex === newPath) {
    return flow;
  }
  const newFlow = cloneDeep(flow);
  newFlow.metadata.triggerFilter.pathRegex = newPath;
  return newFlow;
}

export function setFlowName(flow: IFlow, name: string) {
  const newFlow = cloneDeep(flow);
  newFlow.name = name;
  return newFlow;
}

export function getTriggerColumn(flow: IFlow) {
  return flow.metadata.triggerFilter.isInColumn;
}

export function setTriggerColumn(flow: IFlow, columnNumber: number) {
  const newFlow = cloneDeep(flow);
  newFlow.metadata.triggerFilter.isInColumn = columnNumber;
  return newFlow;
}

export function getNode(flow: IFlow, index = NODE_INDEX) {
  if (!flow?.nodes || index > flow.nodes.length) return undefined;
  return flow.nodes[index];
}

function extractDynamicXeroFieldsFromMappingField(
  mappingConfig: ISingleConfigMapping | IConfigMappingItem,
  xeroIntegrationId: string
) {
  const dynamicDropdownXeroFields = (mapping: MappingField) =>
    mapping.fieldType === MappingFieldTypes.Dropdown &&
    mapping.optionType === 'dynamic' &&
    mapping.integrationId === xeroIntegrationId;

  const fields = [];
  if (mappingConfig.source?.isEditable) {
    fields.push(
      ...(mappingConfig.source.fields.filter(
        dynamicDropdownXeroFields
      ) as IDropdownMappingField[])
    );
  }
  if (mappingConfig.destination?.isEditable) {
    fields.push(
      ...(mappingConfig.destination.fields.filter(
        dynamicDropdownXeroFields
      ) as IDropdownMappingField[])
    );
  }
  return fields;
}

function extractDynamicXeroFieldsFromNode(node?: INode) {
  if (!node) {
    return [];
  }

  const xeroIntegrationId = node.integrationId;
  const mappings = node.config?.mappings as ConfigMapping[];

  return (
    mappings
      ?.map((mapping) => {
        if (!mapping.isMultiple) {
          return extractDynamicXeroFieldsFromMappingField(
            mapping,
            xeroIntegrationId
          );
        }
        // TODO - Enforcing v1 mapping-config type for now - a proper type check should be implemented as part of client tickets
        return (mapping.items as IConfigMappingItem[])
          .map((item) =>
            extractDynamicXeroFieldsFromMappingField(item, xeroIntegrationId)
          )
          .flat();
      })
      .flat() ?? []
  );
}

export function setConnectionId(
  node: INode,
  connectionId: string,
  optionSource: string
) {
  const newNode = cloneDeep(node);
  newNode.connectionId = connectionId;

  const dynamicXeroFields = extractDynamicXeroFieldsFromNode(newNode);

  dynamicXeroFields.forEach((field) => {
    // eslint-disable-next-line no-param-reassign
    field.optionSource = optionSource;
  });

  return newNode;
}

export function getNodeSettings<NodeConfigSettingsType>(node?: INodeConfig) {
  return node?.settings as NodeConfigSettingsType;
}

export function setDataExportTypeSetting(
  node: INodeConfig,
  dataExportType: CsvDataExportTypes
) {
  const newNode = cloneDeep(node);
  const settings = getNodeSettings<IHostedFileNodeConfigSettings>(newNode);
  settings.dataExportType = dataExportType;
  return newNode;
}

export function getDataExportTypeSetting(node?: INodeConfig) {
  return getNodeSettings<IHostedFileNodeConfigSettings>(node)?.dataExportType;
}

export function getTimesheetExistsSetting(node?: INodeConfig) {
  return getNodeSettings<IXeroTimesheetNodeConfigSettings>(node)
    ?.timesheetExists;
}

export function setTimesheetExistsSetting(
  node: INodeConfig,
  setting: ITimesheetUpdateRule
) {
  const newNode = cloneDeep(node);
  const settings = getNodeSettings<IXeroTimesheetNodeConfigSettings>(newNode);
  settings.timesheetExists = setting;
  return newNode;
}

/**
 * Updates the mappings property inside a node configuration.
 *
 * @param nodeConfig - The current configuration property of the node.
 * @param newConfig - The new mapping configuration to be applied.
 * @returns The updated node configuration.
 */
export function updateMappingConfig(
  nodeConfig: INodeConfig,
  newConfig: ConfigMapping
) {
  const newMappings = [...nodeConfig.mappings];
  const mappingIndex = newMappings.findIndex(
    (mapping) => mapping.key === newConfig.key
  );
  newMappings[mappingIndex] = newConfig;

  return {
    ...nodeConfig,
    mappings: newMappings,
  } as INodeConfig;
}

/**
 *
 * Takes in the mapping set and the single mapping which is to be updated
 * Updates the single mapping returns the mapping set containing the updated mapping
 *
 * @param mappingSet the mapping set which contains the mapping to be updated
 * @param newMappingConfig the new mapping config to be updated
 * @returns
 */
export function updateMappingConfigSet(
  mappingSet: IConfigMappingSet,
  newMappingConfig: ISingleConfigMapping
) {
  const newMappingSet = cloneDeep(mappingSet.mappings);
  const mappingSetIndex = newMappingSet.findIndex(
    (mapping) => mapping.key === newMappingConfig.key
  );
  newMappingSet[mappingSetIndex] = newMappingConfig;

  return {
    ...mappingSet,
    mappings: newMappingSet,
  } as IConfigMappingSet;
}

/**
 * Takes in the top level nodeConfig, the multiMappingConfig which sits within it and the
 * mapping set which has already been updated and returns the updated node mapping configuration containing the updated
 * mapping set.
 *
 * @param nodeConfig the top level node mapping configuration, which houses all the mappings
 * @param multiMappingConfig the multi mapping configuration which contains the mapping set to be updated
 * @param updatedMappingSet the mapping set which has already been updated
 * @returns updated node mapping configuration
 */
export function updateMultiSetMappingConfig(
  nodeConfig: INodeConfig,
  multiMappingConfig: IMultipleSetConfigMapping,
  updatedMappingSet: IConfigMappingSet
) {
  // TODO fix this use of any - we should be able to identify the correct type here but node-config.model.ts needs
  // to be updated to support this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newMappings: any[] = cloneDeep(nodeConfig.mappings);

  const mappingIndex = newMappings.findIndex(
    (mapping) => mapping.key === multiMappingConfig.key
  );

  const mappingSetIndex = newMappings[mappingIndex].items.findIndex(
    (item: IConfigMappingSet) => item._id === updatedMappingSet._id
  );

  // TODO Handle invalid index here?
  newMappings[mappingIndex].items[mappingSetIndex] = updatedMappingSet;

  return {
    ...nodeConfig,
    mappings: newMappings,
  } as INodeConfig;
}

/**
 * Resets the mapping definition by creating a deep clone of the provided mapping definition and assigning new unique identifiers to each field.
 * Optionally, it can also reset the value data of the mapping definition.
 *
 * @param mappingDefinition - The mapping definition to reset.
 * @param options - An optional object that may include a `resetValueData` boolean. If `resetValueData` is true, the value data of the mapping definition will be reset. Defaults to true.
 * @returns A new mapping definition with reset fields and possibly reset value data.
 */
export function resetMappingDefinition(
  mappingDefinition: IMappingDefinition,
  options: { resetValueData?: boolean } = { resetValueData: true }
) {
  const newDefinition = cloneDeep(mappingDefinition);
  newDefinition.fields = newDefinition.fields.map((field) => ({
    ...field,
    _id: ulid(),
  }));

  if (options.resetValueData) {
    newDefinition.valueData = undefined;
  }

  return newDefinition;
}

/**
 * Adds a new mapping item to a given multi-single mapping configuration.
 *
 * @param node - The node configuration object.
 * @param config - The multi-single mapping configuration object.
 *
 * @throws If the multi-single mapping configuration object has no items.
 * @returns The updated mapping configuration after adding the new item.
 */
export function addMappingItem(
  node: INodeConfig,
  config: IMultipleSingleConfigMapping
) {
  if (config.items.length === 0) {
    throw new Error('Cannot add mapping item to empty mapping config');
  }

  // safe to index to zero due to guards ensuring multimapping items should always have data
  const itemTemplate = cloneDeep(config.items[0]);
  itemTemplate._id = ulid();
  itemTemplate.source.fields = itemTemplate.source.fields.map((field) => ({
    ...field,
    _id: ulid(),
  }));
  itemTemplate.source.valueData = undefined;
  itemTemplate.destination.fields = itemTemplate.destination.fields.map(
    (field) => ({
      ...field,
      _id: ulid(),
    })
  );
  itemTemplate.destination.valueData = undefined;

  const updatedConfig = {
    ...config,
    items: [...config.items, itemTemplate],
  };

  return updateMappingConfig(node, updatedConfig);
}

/**
 * Adds a new item to a given multiple set configuration.
 *
 * @param node - The node configuration object.
 * @param config - The multiple set configuration object.
 *
 * @throws If the multiple set configuration object has no items.
 * @returns The updated multiple set configuration after adding the new item.
 */
export function addMultiMappingConfigItem(
  node: INodeConfig,
  config: IMultipleSetConfigMapping
) {
  // safe to index to zero due to guards ensuring multimapping items should always have data
  const itemTemplate = cloneDeep(config.items[0]);
  itemTemplate._id = ulid();

  const itemTemplateAsIConfigMappingItemSet =
    itemTemplate as unknown as IConfigMappingSet;

  if (itemTemplateAsIConfigMappingItemSet.mappings.length === 0) {
    throw new Error(
      'Cannot add mapping item to empty multi-mapping set config'
    );
  }

  itemTemplateAsIConfigMappingItemSet.mappings =
    itemTemplateAsIConfigMappingItemSet.mappings.map((mapping) => ({
      ...mapping,
      source: resetMappingDefinition(mapping.source),
      destination: resetMappingDefinition(mapping.destination, {
        resetValueData: false,
      }),
      _id: ulid(),
    }));

  const updatedConfig = {
    ...config,
    items: [...config.items, itemTemplateAsIConfigMappingItemSet],
  };

  return updateMappingConfig(node, updatedConfig);
}

export function updateMappingItem(
  node: INodeConfig,
  config: IMultipleSingleConfigMapping,
  item: IConfigMappingItem
) {
  const newItems = [...config.items];
  const itemIndex = newItems.findIndex((mapping) => mapping._id === item._id);
  newItems[itemIndex] = item;
  const updatedConfig = { ...config, items: newItems };

  return updateMappingConfig(node, updatedConfig);
}

/**
 * Deletes a specific mapping item from the configuration.
 *
 * @param node - The node configuration.
 * @param config - The multi-mapping configuration.
 * @param deleteMapping - The mapping configuration item to delete.
 * @returns The updated mapping configuration after deletion.
 */
export function deleteMappingItem(
  node: INodeConfig,
  config: IMultipleSingleConfigMapping,
  deleteMapping: IConfigMappingItem
) {
  const updatedConfig = {
    ...config,
    items: config.items.filter((item) => item._id !== deleteMapping._id),
  };

  return updateMappingConfig(node, updatedConfig);
}

/**
 * Deletes a specific mapping configuration from a multiple set configuration.
 *
 * @param node - The node configuration where the multiple set configuration is located.
 * @param multiMappingConfig - The multiple set configuration from which to delete the mapping configuration.
 * @param deleteMapping - The mapping configuration to delete.
 * @returns The updated mapping configuration after deletion.
 */
export function deleteMultiSetMappingConfig(
  node: INodeConfig,
  multiMappingConfig: IMultipleSetConfigMapping,
  deleteMapping: IConfigMappingSet
) {
  const updatedConfig = {
    ...multiMappingConfig,
    items: multiMappingConfig.items.filter(
      (item) => item._id !== deleteMapping._id
    ),
  };

  return updateMappingConfig(node, updatedConfig);
}

export function setSourceValueData<
  T extends ISingleConfigMapping | IConfigMappingItem,
>(mappingConfig: T, valueData?: IInputDataValueData | IDropdownValueData) {
  const newMapping = cloneDeep(mappingConfig);
  newMapping.source.valueData = valueData;
  return newMapping;
}

export function setDestinationValueData<
  T extends ISingleConfigMapping | IConfigMappingItem,
>(mappingConfig: T, valueData?: IInputDataValueData | IDropdownValueData) {
  const newMapping = cloneDeep(mappingConfig);
  newMapping.destination.valueData = valueData;
  return newMapping;
}

export function setNode(flow: IFlow, node: INode, index = NODE_INDEX) {
  const newFlow = cloneDeep(flow);
  newFlow.nodes[index] = node;
  return newFlow;
}

export function setNodeConfig(
  flow: IFlow,
  nodeConfig: INodeConfig,
  index = NODE_INDEX
) {
  const newFlow = cloneDeep(flow);
  newFlow.nodes[index].config = nodeConfig;
  return newFlow;
}
