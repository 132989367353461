import Container from 'typedi';

import { Template } from '@site-mate/dashpivot-shared-library';
import {
  INodeConfig,
  ISingleConfigMapping,
  MappingConfigService,
} from '@site-mate/sitemate-flowsite-shared';

import { MappingItem } from '@/pages/flows/components/flows/MappingItem';
import { WrappedMappingConfig } from '@/pages/flows/types/components';
import { nodeService } from '@/services';

/**
 * Props for FixedMappings component
 *
 * @param node - the node config
 * @param template - the template
 * @param WrappedMappingConfigs - the mapping configs to be rendered
 * @param onNodeChange - callback for when the node config is updated
 * @param header - the header for the table
 */
type FixedMappingsProps = {
  node: INodeConfig;
  template?: Template;
  wrappedMappingConfigs: WrappedMappingConfig[];
  onNodeChange: (config: INodeConfig) => void;
  header: string;
};

export const FixedMappings = ({
  node,
  template,
  wrappedMappingConfigs,
  onNodeChange,
  header,
}: FixedMappingsProps) => {
  const mappingService = Container.get(MappingConfigService);
  const handleMappingChange = (newMapping: ISingleConfigMapping) => {
    onNodeChange(nodeService.updateMappingConfig(node, newMapping));
  };
  return (
    <>
      <p className="mb-2 text-sm text-default-text">{header}</p>
      <table className="mb-2 w-full border-collapse border border-grey-2 text-left">
        <thead className="bg-grey-2 text-sm">
          <tr>
            <th className="border-slate-600 w-1/2 border p-2">Source</th>
            <th className="border-slate-600 w-1/2 border p-2">Destination</th>
          </tr>
        </thead>
        <tbody>
          {wrappedMappingConfigs.map((wrappedMappingConfig) => {
            const { mappingKey, mappingLabel, dropdownOptions } =
              wrappedMappingConfig;

            const mappingConfig = mappingService.getMappingConfigForKey(
              mappingKey,
              node
            );

            return mappingConfig ? (
              <MappingItem
                key={mappingConfig._id}
                mappingConfig={mappingConfig}
                configKey={mappingKey}
                template={template}
                sourceLabel={mappingLabel.source}
                destinationLabel={mappingLabel.destination}
                onMappingChange={handleMappingChange}
                dropdownOptions={dropdownOptions}
              />
            ) : null;
          })}
        </tbody>
      </table>
    </>
  );
};
