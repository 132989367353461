import {
  ConfigMapping,
  IInvoiceUpdateRule,
  IntegrationType,
} from '@site-mate/sitemate-flowsite-shared';

import { IntegrationsMap } from '@/hooks';
import { ICreateProps } from '@/pages/flows/types';

import { mappingConfigs } from '../mappings/mappings';

export const generateQuickbooksConfig = (
  createProps: ICreateProps,
  integrationsMap: IntegrationsMap
) => {
  const { nodeType } = createProps;

  const dashpivotId = integrationsMap.get(IntegrationType.DASHPIVOT)?._id;
  const quickbooksId = integrationsMap.get(IntegrationType.QUICKBOOKS)?._id;

  const config = {
    mappings: mappingConfigs[nodeType](
      dashpivotId,
      quickbooksId
    ) as ConfigMapping[],
    settings: {
      invoiceExists: IInvoiceUpdateRule.CreateNew,
    },
  };

  return config;
};
